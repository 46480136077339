import MUIDataTable from "mui-datatables";
import { useState } from "react";
import SimpleDialog from './SimpleDialog'
import styles from './Table.module.css'

const options = {
  filter: false,
  search: false,
  selectableRowsHeader: false,
  selectableRowsOnClick: false,
  selectableRowsHideCheckboxes: true,
}

export default function TotemTable(props) {
  const [photoModal, setModalPhoto] = useState(false)
  const [photo, setPhoto] = useState('')

  const columns = [
    {
      name: "active",
      label: "Status",
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "servidor",
      label: "Servidor",
      options: {
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: "ip",
      label: "IP",
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "acesso",
      label: "Acesso",
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "similaridade_off",
      label: "Similaridade Off",
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "similaridade_aws",
      label: "Similaridade AWS",
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "img",
      label: "Foto",
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button onClick={() => {
              setModalPhoto(true)
              setPhoto(value)
            }}
              className={styles.imgBTN}
            >
              <img
                src={value}
                key={tableMeta.columnIndex}
                style={{ width: "40px", height: "25px" }}
              />
            </button>
          );
        },
      }
    },

  ];
  return (
    <div>
      <MUIDataTable
        title={props.totem}
        data={props.data}
        columns={columns}
        options={options}
      />
      <SimpleDialog
        data_photo={photo}
        open={photoModal}
        onClose={() => { setModalPhoto(false) }}
      />
    </div>
  )
}