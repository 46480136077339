import styles from './Login.module.css'
import logo from '../assets/logoantigo.png'
import { useCallback, useRef, useState } from 'react'
import LoginAuth from '../context/LoginContext'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


export default function Login() {
  const [openPasswordError, setOpenPasswordError] = useState()
  const formRef = useRef()
  const { setToken, setNome } = useContext(LoginAuth)
  let navigate = useNavigate()
  const URL_LOGIN = "https://letmein.zionstech.com/api/login"

  const fetchLogin = useCallback(async () => {
    const response = await fetch(URL_LOGIN, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "username": formRef.current[0].value, "password": formRef.current[1].value })
    })
    if (response.status === 201) {
      setNome(formRef.current[0].value)
      const data = await response.json()
      setToken(data.token)
      navigate('/')
      window.location.reload()
    } else {
      setOpenPasswordError(true)
    }
  }, [])

  function handleForm(e) {
    e.preventDefault()

    fetchLogin()
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenPasswordError(false)
  };

  return (
    <div className={styles.loginMain}>
      <Snackbar open={openPasswordError} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity="error">
          Senha ou usuário invalido !
        </Alert>
      </Snackbar>
      <div className={styles.logBox}>
        <img src={logo} alt="ZIONS" />
        <h1>LOGIN</h1>
        <form ref={formRef} id="forms">
          <input type="text" placeholder="Usuário ou E-mail" id="email" name="email" />
          <input type="password" placeholder="Senha" id="password" name="password" />
          <div className={styles.check}>
            <input type="checkbox" id="lembre" name="lembre" />
            <label htmlFor="lembre">Lembre-me</label>
          </div>
          <button type="submit" onClick={handleForm}>ACESSAR</button>
        </form>
        {/* <Link to="/newpassword"><p>Esqueceu sua senha ?</p></Link> */}
      </div>
    </div>
  )
}