import MUIDataTable from "mui-datatables";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import TotemContext from '../context/TotemContext'
import styles from './Table.module.css'


const options = {
  filter: false,
  search: false,
  selectableRowsHeader: false,
  selectableRowsOnClick: false,
  selectableRowsHideCheckboxes: true,
  sortOrder: {
    name: 'condominioCAPS',
    direction: 'asc',
}
};

export default function Table(props) {
  const navigate = useNavigate()

  const { setServerName } = useContext(TotemContext)
  const columns = [{
    name: "condominioCAPS",
    label: "Condomínios",
    options: {
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <button onClick={() => {
            setServerName(value)
            navigate('/totem')
          }}
            className={styles.btn}
            key={tableMeta}
          >
            {value}
          </button>
        );
      },
    }
  },
  {
    name: "ativo",
    label: "Ativos",
    options: {
      sort: true,
      sortThirdClickReset: true
    }
  },
  {
    name: "inativo",
    label: "Inativos",
    options: {
      sort: true,
      sortThirdClickReset: true
    }
  },
  ];
  


  return (
    <div className={styles.tableContainer}>
      <MUIDataTable
        title={"TOTENS"}
        data={props.array}
        columns={columns}
        options={options}
      />
      
    </div>
  )
}

