import NumberInfos from "../components/NumberInfos";
import Table from "../components/Table";
import styles from './Main.module.css'
import { PDFDownloadLink } from "@react-pdf/renderer"
import PDFfile from "../components/PDFfile"
import pdfImage from '../assets/pdf.svg'
import pdfInactive from '../assets/inactive.svg'
import { useContext } from "react";
import LoginAuth from "../context/LoginContext";

export default function MainTable(props) {
  const {token} = useContext(LoginAuth)
  return (
    <div className={styles.main}>
      <div style={{ width: "30%", minWidth: '300px' }}>
        <NumberInfos infoName="TOTENS" info={props.total} color="#1A31AB" />
        <NumberInfos infoName="ATIVOS" info={props.ativos} color="#1AAB3A" />
        <NumberInfos infoName="INATIVOS" info={props.inativos} color="#FF0000" />
      </div>
      <div style={{ width: "70%", minWidth: '700px' }}>
        <div style={{width: "80%", display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "1rem auto"}}>
          <PDFDownloadLink document={<PDFfile token={token} />} fileName="Relatorio">
            {({ loading }) => (
              loading ? <button disabled><img src={pdfInactive} /></button> : <button className={styles.btnPDF}><img src={pdfImage} /></button>
            )}
          </PDFDownloadLink>
        </div>
        <Table array={props.totens} />
      </div>
    </div>
  )
}