import TotemContext from "./TotemContext"
import useStorage from '../Hooks/useStorage'

 const TotemProvider = ({children}) => {
   const [serverName, setServerName] = useStorage('server')

  return(
    <TotemContext.Provider
      value={{
        serverName,
        setServerName
      }}
    >
      {children}
    </TotemContext.Provider>
  )
}

export default TotemProvider