import { Route, Routes } from 'react-router-dom';
import './App.css';
import MainTable from './pages/MainTable';
import Totem from './pages/Totem';
import { useCallback, useState, useEffect, useContext, Fragment } from 'react';
import Login from './pages/Login';
import LoginAuth from './context/LoginContext';
import { useNavigate } from 'react-router-dom';
import Private from './components/Private/Private'

function App() {
  const URL_ALL_TOTENS = 'https://letmein.zionstech.com/api/list-totem'
  const [totem, setTotens] = useState({})
  const [array, setArray] = useState([])
  const { token, setToken } = useContext(LoginAuth)
  const navigate = useNavigate()

  function expireHandle() {
    setTimeout(() => {
      setToken(null)
      navigate('/login')
      window.location.reload()
    }, 5000)
  }

  const fetchTotens = useCallback(async () => {
    const response = await fetch(URL_ALL_TOTENS, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    })
    if (response.status === 401) {
      expireHandle()
    } else {
      const data = await response.json()
      const transformedData = {
        Actives: data.totem_active,
        Inactives: data.totem_inactive,
        Total: data.totem_total,
        array_totens: data.totem_list.map((totem) => {
          return {
            condominio: totem.server,
            condominioCAPS: totem.server.toUpperCase(),
            ativo: totem.active,
            inativo: totem.inactive,
          }
        }),
      }
      console.log(transformedData)
      setTotens(transformedData)
      setArray(transformedData.array_totens)
    }
  }, [])

  useEffect(() => {
    if (token) {
      fetchTotens()
    }
  }, [token])
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={
          <Private>
            <MainTable
              total={totem.Total}
              ativos={totem.Actives}
              inativos={totem.Inactives}
              totens={array}
            />
          </Private>
        } />
        <Route path="/totem" element={
          <Private>
            <Totem />
          </Private>
        } />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Fragment>
  );
}

export default App;
