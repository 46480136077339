import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import close from '../assets/close.svg'
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';


export default function JSONDialog(props) {

  const handleClose = () => {
    props.close();
  };
  var JSONPretty1337 = require('react-json-pretty/dist/1337');


  return (
    <Dialog
      onClose={handleClose}
      open={props.modal}
      fullWidth={true}
      maxWidth="lg"
      sx={{ backgroundColor: "black" }}
    >
      <div style={{ backgroundColor: "#000", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row", width: "100%" }}>
          <img src={close} onClick={handleClose} style={{ cursor: "pointer" }} alt="Fechar" />
        </div>
        <JSONPretty theme={JSONPretty1337} data={props.info}></JSONPretty>
      </div>
    </Dialog>
  );
}
