import LoginAuth from "./LoginContext"
import useStorage from '../Hooks/useStorage'

 const AuthProvider = ({children}) => {
   const [token, setToken] = useStorage('token')
   const [nome, setNome] = useStorage('nome')

  return(
    <LoginAuth.Provider
      value={{
        token,
        setToken,
        setNome,
        nome
      }}
    >
      {children}
    </LoginAuth.Provider>
  )
}

export default AuthProvider