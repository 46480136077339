import styles from './NumberInfos.module.css'
import CountUp from 'react-countup';

export default function NumberInfos(props) {
  return (
    <div className={styles.boxInfo}>
      <h1>{props.infoName}</h1>
      <CountUp start={0} end={props.info} duration={2}>
        {({ countUpRef }) => (
            <span style={{ color: props.color}} ref={countUpRef} />
        )}
      </CountUp>
    </div>
  )
}