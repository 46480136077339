import styles from './Card.module.css';
import more from '../assets/more.svg'
import { useState } from 'react';
import JSONDialog from './JSONDialog';

export default function Card(props){
  const [modal, setModal] = useState(false)
  const modalHandle = () => {
    setModal(true)
  }
  const close = () => {
    setModal(false)
  }
  return(
    <div className={styles.card}>
      <div className={styles.cardImg}><img src={props.server.img}/></div>
      <div className={styles.cardInfos}><span>Status</span><span>{props.server.active}</span></div>
      <span className={styles.divisor}></span>
      <div className={styles.cardInfos}><span>Servidor</span><span>{props.server.servidor}</span></div>
      <span className={styles.divisor}></span>
      <div className={styles.cardInfos}><span>IP</span><span>{props.server.ip}</span></div>
      <span className={styles.divisor}></span>
      <div className={styles.cardInfos}><span>Nome</span><span>{props.server.nome}</span></div>
      <span className={styles.divisor}></span>
      <div className={styles.cardInfos}><span>Acesso</span><span>{props.server.acesso}</span></div>
      <span className={styles.divisor}></span>
      <div className={styles.cardInfos}><span>Similaridade off.</span><span>{props.server.similaridade_off}</span></div>
      <span className={styles.divisor}></span>
      <div className={styles.cardInfos}><span>Similaridade aws.</span><span>{props.server.similaridade_aws}</span></div>
      <span className={styles.divisor}></span>
      <div className={styles.cardBtn}><button onClick={() => modalHandle()}>Mostrar Mais <img src={more} alt="Ver Mais" style={{marginLeft: '1rem'}} /></button></div>
      <JSONDialog info={props.server.general} modal={modal} close={close} />
    </div>
  )
}