import React, { Fragment, useContext } from 'react'
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import { useCallback, useEffect, useState } from "react"
import logo from '../assets/logo.png'
// import LoginAuth from '../context/LoginContext';
// import { useNavigate } from 'react-router-dom';


// Font.register({ family: "Inter", fonts: [
// 	{ src: '../assets/fonts/Inter-Bold.ttf', fontWeight: 700 },
// 	{ src: '../assets/fonts/Inter-Regular.ttf', fontWeight: 400 },
//   ]})

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  divisor: {
    borderBottom: '1px solid #000',
    margin: 10
  },
  lightDivisor: {
    width: '95%',
    borderBottom: '1px solid #d7d7d7',
    marginVertical: 10,
    marginHorizontal: 'auto'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: "justify",
    marginLeft: 20,
    marginVertical: 10
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontWeight: 400
  },
  image: {
    marginVertical: 15,
    width: "100%",
    justifyContent: 'flex-start',
    alignSelf: 'center',
  },
  totemInfoLeft: {
    fontSize: 12,
    textAlign: "justify",
    marginLeft: 20,
    marginVertical: 10,
    fontWeight: 400
  },
  totemInfoRight: {
    fontSize: 12,
    textAlign: "justify",
    marginRight: 20,
    marginVertical: 10,
    fontWeight: 400
  },
  totemInfoMid: {
    fontSize: 12,
    textAlign: "center",
    marginHorizontal: 20,
    marginVertical: 10,
    fontWeight: 400
  },
  wrapper: {
    width: "50%"
  },
  formatSpace: {
    width: "100%",
    justifyContent: 'space-between',
    alignSelf: 'center',
    flexDirection: "row",
  },
  serverName: {
    fontSize: 14,
    textAlign: "justify",
    marginLeft: 20,
    marginVertical: 10,
    fontWeight: 'bold'
  },
  serverValue: {
    fontSize: 14,
    textAlign: "justify",
    marginRight: 20,
    marginVertical: 10,
    fontWeight: 'bold'
  },
});

export default function PDFfile(props) {
  const URL_REPORT = "https://letmein.zionstech.com/api/report"
  const [report, setReport] = useState({})
  const [reportArray, setReportArray] = useState([])
  // const {setToken} = useContext(LoginAuth)
  // const navigate = useNavigate()

  // function expireHandle() {
  //   console.log("expirou")
  //   setTimeout(() => {
  //     setToken(null)
  //     navigate('/login')
  //     window.location.reload()
  //   }, 5000)
  // }

  const fetchReport = useCallback(async () => {
    const response = await fetch(URL_REPORT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': props.token
      }
    })
    if (response.status === 401) {
      // expireHandle()
    } else {
      const data = await response.json()
      const transformedData = {
        total: data.price_total,
        total_totem: data.totem_total,
        servers: data.servers.map((server) => {
          return {
            serverName: server.server,
            totens: server.totem,
            totalServer: server.totem.length * 39.90
          }
        }),
      }
      console.log(transformedData.servers)
      setReport(transformedData)
      setReportArray(transformedData.servers)
    }
  }, [])

  useEffect(() => {
    fetchReport()
  }, [fetchReport])
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.image}><Image style={{ width: 80, height: 80, marginLeft: 20 }} src={logo} /></View>
        <Text style={styles.divisor}></Text>
        <View style={styles.formatSpace}>
          <Text style={styles.text}>Vigência: 29/02/2022 até 29/03/2022</Text>
          <Text style={styles.text}>Cliente: Letmein</Text>
        </View>
        <View style={styles.formatSpace}>
          <Text style={styles.text}>Dia da emissão: 29/03/2022</Text>
          <Text style={styles.text}>Nº: 001</Text>
        </View>
        <Text style={styles.divisor}></Text>
        <View style={styles.formatSpace}>
          <Text style={styles.title}>Número de totens</Text>
          <Text style={styles.title}>{report.total_totem}</Text>
        </View>
        <View style={styles.formatSpace}>
          <Text style={styles.title}>Total</Text>
          <Text style={styles.title}>{report.total}</Text>
        </View>
        <Text style={styles.divisor}></Text>
        <Text style={styles.title}>Detalhes</Text>
        <Text style={styles.divisor}></Text>
        {reportArray.map((server) => (
          <Fragment>
            <View style={styles.formatSpace}>
              <Text style={styles.serverName}>Server: {server.serverName}</Text>
              <Text style={styles.serverValue}>Valor: R$ {server.totalServer.toFixed(2)}</Text>
            </View>
            <Text style={styles.lightDivisor}></Text>
            {server.totens.map(totem => (
              <Fragment>
                <View style={styles.formatSpace}>
                  <Text style={styles.totemInfoLeft}>{totem.tipo}</Text>
                  <View style={styles.wrapper}><Text style={styles.totemInfoMid}>Nº {totem.acesso}</Text></View>
                  <Text style={styles.totemInfoRight}>{totem.price_unit}</Text>
                </View>
                <Text style={styles.lightDivisor}></Text>
              </Fragment>
            ))}

            <Text style={styles.divisor}></Text>
          </Fragment>
        ))}
      </Page>
    </Document>
  )
}