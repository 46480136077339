import { useCallback, useContext, useEffect, useState } from "react"
import Card from "../components/Card"
import TotemTable from "../components/TotemTable"
import TotemContext from "../context/TotemContext"
import styles from './Main.module.css'
import cards from '../assets/cards.svg'
import tables from '../assets/table.svg'
import LoginAuth from "../context/LoginContext"
import { useNavigate } from 'react-router-dom';

export default function Totem(props) {
  const { serverName } = useContext(TotemContext)
  const [totem, setTotem] = useState([])
  const [table, setTable] = useState(false)
  const { token, setToken } = useContext(LoginAuth)
  const URL_TOTEM = "https://letmein.zionstech.com/api/list-totem-by-server"
  const navigate = useNavigate()

  function expireHandle() {
    console.log("expirou")
    setTimeout(() => {
      setToken(null)
      navigate('/login')
      window.location.reload()
    }, 5000)
  }


  const fetchTotem = useCallback(async () => {
    const response = await fetch(URL_TOTEM, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify({ "server": serverName.toLowerCase() })
    })
    if (response.status === 401) {
      expireHandle()
    } else {
      const data = await response.json()
      const transformedData = data.map((dataServer) => {
        return {
          active: dataServer.active ? "Ativo" : "Desativado",
          general: dataServer,
          img: dataServer.image,
          servidor: dataServer.server,
          ip: dataServer.config.flask_IP,
          nome: dataServer.hostname,
          acesso: dataServer.config.param_access,
          similaridade_off: dataServer.config_app.min_confidence_level_offiline + '%',
          similaridade_aws: dataServer.config.param_similarity + '%',
        }
      })


      console.log(transformedData)
      setTotem(transformedData)
    }
  }, [])

  useEffect(() => {
      fetchTotem()
  }, [fetchTotem])

  var cardStyle = ''
  var tableStyle = ''

  if (!table) {
    cardStyle = styles.disable
    tableStyle = ''
  } else {
    cardStyle = ''
    tableStyle = styles.disable
  }
  return (
    <div className={styles.full}>
      <div className={styles.btnContainer}>
        <button className={cardStyle} onClick={() => { setTable(false) }}><img src={cards} /></button>
        <button className={tableStyle} onClick={() => { setTable(true) }}><img src={tables} /></button>
      </div>


      <div className={styles.totem}>
        {!table ? totem.map((TOTEM_UNIT, index) =>
          <Card server={TOTEM_UNIT} key={index} />
        ) :
          <TotemTable data={totem} totem={totem.servidor} />
        }
      </div>
    </div>
  )
}