import house from '../assets/house.svg'
import adm from '../assets/adm.svg'
import back from '../assets/back.svg'
import logo from '../assets/logo.svg'
import styles from './Header.module.css'
import { Link, useLocation } from 'react-router-dom'
import { useContext, useState, useEffect, Fragment } from 'react'
import TotemContext from '../context/TotemContext'
import LoginAuth from '../context/LoginContext'
import { useNavigate } from "react-router-dom";


export default function Header() {
  let location = useLocation()
  const {setToken, nome, setNome} = useContext(LoginAuth)
  const [login, setLogin] = useState(false)
  const navigate = useNavigate();


  const handleLogout = () => {
    setToken(null)
    setNome(null)
    let path = '/login'; 
    navigate(path);
  }

  useEffect(() => {
    if (location.pathname == '/login') {
      setLogin(true)
    } else {
      setLogin(false)
    }
  }, [location])

  const { serverName } = useContext(TotemContext)
  var title = <div></div>
  var leftIcon = <img src={house} />
  if (location.pathname === '/totem') {
    title = <div style={{ color: '#fff', marginLeft: "8rem" }}>{serverName}</div>
    leftIcon = <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => { window.location.reload() }}><Link to="/"><img src={back} /></Link></button>
  } else {
    title = <div></div>
    leftIcon = <img src={house} />
  }

  return (
    <Fragment>
      {!login &&
        <div className={styles.header}>
          <div className={styles.leftSide}>
            {leftIcon}
          </div>
          {title}
          <div className={styles.rightSide}>
            <img src={adm} />
            <p>{nome ? nome : "ADM"}</p>
            <img src={logo} />
            <button className={styles.logout} onClick={() => {handleLogout()}}>Log Out</button>
          </div>
        </div >
      }
    </Fragment>
  )
}