import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Header from './components/Header';
import TotemProvider from './context/Provider';
import LoginProvider from './context/LoginProvider';

ReactDOM.render(
  <Router>
    <LoginProvider>
      <TotemProvider>
        <Header />
        <App />
      </TotemProvider>
    </LoginProvider>
  </Router>,
  document.getElementById('root')
);
