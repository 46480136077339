import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import close from '../assets/close.svg'


export default function SimpleDialog(props) {

  const handleClose = () => {
    props.onClose();
  };


  return (
    <Dialog 
      onClose={handleClose} 
      open={props.open}
      fullWidth={true}
      maxWidth="md"
      sx={{backgroundColor: "white"}}
    >
      <div style={{backgroundColor: "#fff", padding: "1rem", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <div style={{marginBottom: "1rem",display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row", width:"100%"}}>
          <img src={close} onClick={handleClose} style={{cursor: "pointer"}} alt="Fechar" />
        </div>
        <div style={{backgroundColor: "#fff",width: "100%", height: "100%", padding: "1rem", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
          <img src={props.data_photo} style={{width: "720px", height: "480px"}} />
        </div>
      </div>
    </Dialog>
  );
}
